import * as React from "react"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import App from "../components/app"

import "../styles/index.scss"

import favicon from "../images/star-4.png"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wish Fountain</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <App />
    </Layout>
  )
}

export default IndexPage
